<template>
  <div class="dealer-alarm__group">
    <el-row class="dealer-alarm-group">
      <el-col :span="6" class="dealer-alarm-group-item">
        <div class="group-item-container center" @click="addGroupVisible=true">
          <p><i class="el-icon-plus create"></i></p>
          <p><span>新增分组</span></p>
        </div>
      </el-col>
      <el-col v-for="a in 10" :span="6" class="dealer-alarm-group-item">
        <div class="group-item-container">
          <p>
            <span class="group-item-label">分组名称:</span>
            <span class="group-item-value">张三分组</span>
            <span class="edit-group"><i class="el-icon-more"></i></span>
          </p>
          <p>
            <span class="group-item-label">通知人:</span>
            <span class="group-item-value">里斯本</span>
          </p>
          <p><span class="group-item-label">接收预警类型:</span>
            <el-tag size="mini">停机预警</el-tag>
            <el-tag size="mini">余量预警</el-tag>
            <el-tag size="mini">故障预警</el-tag>
            <el-tag size="mini">异常预警</el-tag>
            <el-tag size="mini">期望预警</el-tag>
          </p>
        </div>
      </el-col>
    </el-row>
    <slider :visible.sync="addGroupVisible"
            :width="'45%'">
      <div>新增通知分组</div>
      <el-form size="mini">
        <el-form-item label="分组名称">
          <el-input :value="addGroup.name" placeholder="请输入分组名称"/>
        </el-form-item>

        <el-form-item label="通知责任人">
          <el-select v-model="addGroup.notifier">
            <el-option :value="employee.uuid" v-for="(employee, key) in employees" :key="key">{{employee.name}}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="通知类型">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll">全选</el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="addGroup.notifyType">
            <el-checkbox v-for="(notifyType, key) in notifyTypes" :label="notifyType.type" :key="key">{{notifyType.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="关联设备">
          <el-transfer
            filterable
            :filter-method="filterMethod"
            filter-placeholder="请输入设备名称"
            v-model="addGroup.deviceUuid"
            :data="devices">
          </el-transfer>
        </el-form-item>
      </el-form>
    </slider>
  </div>
</template>

<script>
  import Slider from '@/components/Slider'

  export default {
    name: '',
    components: {
      Slider
    },
    mounted: function () {
    },
    data: function () {
      return {
        addGroupVisible: false,
        isIndeterminate: false,
        checkAll: false,
        addGroup: {
          name: '',
          notifier: '',
          notifyType: [],
          deviceUuid: []
        },
        employees: [],
        notifyTypes: [
          {name: '停机预警', type: 1},
          {name: '余量预警', type: 2},
          {name: '故障预警', type: 4},
          {name: '异常预警', type: 8},
          {name: '期望预警', type: 16},
        ],
        devices:[{key: '11111', label: '测试'}]
      }
    },
    methods: {
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1;
      }
    },
  }
</script>

<style lang="scss">
  .dealer-alarm__group {
    .dealer-alarm-group {
      margin-left: -16px;

      &-item {
        .center {
          text-align: center;
          cursor: pointer;
        }

        .center:hover {
          border-color: $_theme_color !important;
          color: $_theme_color;
        }

        .group-item-container {
          border: 1px solid;
          margin-left: $padding;
          margin-bottom: $padding;
          @include set_border_color($--border-color, $--border-color-dark);
          border-radius: $border-radius;
          height: 120px;
          padding: $padding;


          .create {
            margin-top: 36px;
            font-size: 20px;
          }

          .edit-group {
            float: right;
            color: $_theme_color;
            cursor: pointer;
          }

          .el-tag {
            margin-right: $padding-8;
          }

          .group-item-label {
            font-size: 12px;
            min-width: 60px;
            display: inline-block;
            margin-right: $padding-8;
          }

          .group-item-value {
            font-size: 13px;
          }
        }
      }
    }
  }


  body.dark .el-tag {
    background: transparent;
    font-size: 12px;
  }
</style>
